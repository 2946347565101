import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"
import A from "../../../components/FastLink/A"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Czym jest SEO? Na czym polega optymalizacja strony internetowej?",
        description:
          "Działania SEO mają na celu zapewnienie stronie WWW jak najwyższej pozycji w wynikach wyszukiwania. Sprawdź, z czego składa się podstawowa optymalizacja. ",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/na-czym-polega-seo-jak-wykonac-optymalizacje-strony-internetowej/",
                name: "Czym jest SEO? Na czym polega optymalizacja strony internetowej?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/na-czym-polega-seo-jak-wykonac-optymalizacje-strony-internetowej",
            text: "Na czym polega SEO? Jak wykonać optymalizację strony internetowej?",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.image1.childImageSharp.fluid.src}
            alt={"Na czym polega SEO? Jak wykonać optymalizację strony internetowej?"}
          />
        }
      >
        <h1>SEO – na czym polega?</h1>
        <p>
          <strong>
            Podstawą do zapewnienia wysokiej pozycji strony internetowej w wyszukiwarce jest odpowiednia optymalizacja
            SEO. Strona WWW powinna być funkcjonalna, przejrzysta i wartościowa dla odwiedzających oraz zgodna z
            aktualnymi wymaganiami robotów Google. Chcesz, żeby Twoja strona działała sprawnie, wyświetlała się wysoko,
            miała dużą liczbę wejść i była interesująca dla użytkowników? Dowiedz się, na czym polega SEO i z jakich
            działań się składa.
          </strong>
        </p>
        <h2>SEO od podstaw</h2>
        <p>
          Optymalizacja strony WWW pod kątem SEO (ang. Search Engine Optimization) polega na wprowadzeniu szeregu zmian
          mających na celu poprawienie jej widoczności w wyszukiwarkach internetowych.
        </p>
        <p>Proces ten obejmuje zarówno aspekty techniczne, jak i treściowe.</p>
        <p>
          W pierwszym przypadku chodzi o takie elementy, jak szybkość ładowania strony, dostosowanie jej do urządzeń
          mobilnych czy poprawne użycie nagłówków HTML.
        </p>
        <p>
          Natomiast optymalizacja treści to głównie tworzenie wartościowych, unikalnych i angażujących tekstów, które
          będą odpowiadały na potrzeby użytkowników oraz spełniały wymogi algorytmów wyszukiwarek.
        </p>
        <p>O wszystkich tych elementach piszemy szczegółowo w dalszej części tego artykułu.</p>
        <h2>Dlaczego warto być wysoko w wynikach wyszukiwania?</h2>
        <p>
          To, ile osób wejdzie na Twoją stronę po wpisaniu danej frazy w okno wyszukiwania, zależy od tego, jakie hasło
          zostało wpisane oraz na której pozycji znajdzie się strona pośród wszystkich wyników.
        </p>
        <p>
          Im wyższe miejsce zajmuje witryna, tym będzie bardziej widoczna w sieci i tym więcej osób ją odwiedzi.{" "}
          <strong>Użytkownicy Internetu najczęściej klikają bowiem w najwyższe pozycje</strong>, ponieważ uważają je za
          sprawdzone i godne zaufania, a także dlatego, że są dla nich po prostu najlepiej zauważalne.
        </p>
        <h2>Działania SEO – z czego się składają? Optymalizacja i pozycjonowanie</h2>
        <h3>Optymalizacja techniczna strony</h3>
        <p>
          Optymalizacja techniczna strony to proces polegający na wprowadzaniu zmian w strukturze, kodzie źródłowym oraz
          innych elementach witryny internetowej, które mają na celu poprawę jej funkcjonowania oraz zwiększenie
          widoczności w wyszukiwarkach. W praktyce oznacza to szereg działań, które można podzielić na kilka kluczowych
          obszarów.
        </p>
        <h3>Szybkość ładowania strony</h3>
        <p>
          Po pierwsze – poprawa wydajności strony. Wysoka szybkość ładowania witryny to jeden z głównych czynników
          wpływających na pozycję Twojej witryny. Dlatego warto zadbać o optymalizację obrazów, skryptów oraz innych
          elementów, które mogą wpływać na czas ładowania strony. Warto również zainwestować w odpowiedni hosting oraz
          serwer, które zapewnią stabilne i szybkie działanie witryny.
        </p>
        <h3>Intuicyjna nawigacja</h3>
        <p>
          Kolejnym ważnym aspektem jest architektura oraz nawigacja. Struktura witryny powinna być przejrzysta i
          intuicyjna, co ułatwi zarówno użytkownikom, jak i robotom wyszukiwarki poruszanie się po niej. Warto
          zastosować hierarchiczną strukturę katalogów oraz odpowiednie oznaczenie nagłówków (H1, H2, H3 itd.), które
          pozwolą na lepsze zrozumienie treści strony.
        </p>
        <h3>Responsywność strony</h3>
        <p>
          Równie istotne jest dostosowanie strony do potrzeb użytkowników mobilnych. Coraz więcej osób korzysta z
          Internetu za pomocą smartfonów i tabletów, dlatego warto zadbać o responsywność witryny oraz jej wygodę
          użytkowania na różnych urządzeniach. Google również premiuje strony przystosowane do urządzeń mobilnych.
        </p>
        <h3>Indeksowanie strony</h3>
        <p>
          Optymalizacja techniczna strony obejmuje również poprawę indeksacji oraz dostępności witryny dla robotów
          wyszukiwarek. W tym celu warto zadbać o prawidłowe ustawienie pliku robots.txt, który informuje roboty o tym,
          które części strony mają być indeksowane. Warto utworzyć również mapę strony (sitemap), która ułatwi robotom
          odnalezienie wszystkich podstron.
        </p>
        <h3>Atrybuty ALT i meta tagi</h3>
        <p>
          Kolejnym elementem jest optymalizacja meta tagów oraz atrybutów alt dla obrazków. Meta tagi to informacje
          umieszczone w kodzie źródłowym strony, które są widoczne tylko dla robotów wyszukiwarek.
        </p>
        <p>
          Warto zadbać o odpowiednie wypełnienie tagów title, description oraz keywords, które wpłyną na to, jak strona
          będzie prezentować się w wynikach Google. Atrybuty alt natomiast pozwalają na opisanie obrazków.
        </p>
        <p>
          Poniżej meta tagi strony <A to="https://wenet.pl/" text={"WeNet.pl"} /> w wynikach Google:
        </p>
        <figure>
          <img src={data.image2.childImageSharp.fluid.src} alt={"Meta tagi WeNet.pl"} />
        </figure>
        <h3>Strony wielojęzyczne</h3>
        <p>
          W przypadku wielojęzycznych witryn istotne jest również ustawienie tzw. hreflang, czyli atrybutu informującego
          roboty wyszukiwarek o dostępnych wersjach językowych strony. Dzięki temu użytkownicy z różnych krajów będą
          widzieć odpowiednią wersję językową witryny, co może wpłynąć na zwiększenie liczby odwiedzin.
        </p>
        <h2>Optymalizacja treści</h2>
        <p>
          Optymalizacja treści opiera się na kilku kluczowych elementach, które są niezbędne do osiągnięcia sukcesu. Do
          najważniejszych z nich należą słowa kluczowe, struktura treści, meta tagi oraz linkowanie wewnętrzne.
        </p>
        <h3>Treści i słowa kluczowe</h3>
        <p>
          Słowa kluczowe to frazy, które użytkownicy wpisują w wyszukiwarkę, aby znaleźć informacje na dany temat. Dobór
          odpowiednich fraz kluczowych jest niezbędny dla skutecznej optymalizacji. Należy wybrać takie frazy, które są
          istotne dla tematyki strony i jednocześnie mają duże prawdopodobieństwo wystąpienia w zapytaniach
          użytkowników.
        </p>
        <h3>Struktura treści</h3>
        <p>
          Struktura treści odgrywa również istotną rolę w procesie optymalizacji. Treść powinna być podzielona na
          logiczne sekcje i zawierać nagłówki oraz akapity, które ułatwiają czytelnikowi poruszanie się po stronie.
          Ponadto warto zadbać o to, aby treść była atrakcyjna wizualnie, na przykład poprzez dodanie zdjęć czy
          ilustracji.
        </p>
        <h3>Meta tagi</h3>
        <p>
          Meta tagi są ważne również w kontekście treści. Warto zadbać o to, aby meta tagi zawierały odpowiednie frazy
          kluczowe oraz opisy, które będą atrakcyjne dla użytkowników i wyszukiwarek.
        </p>
        <h3>Linkowanie wewnętrzne</h3>
        <p>
          Linkowanie wewnętrzne to proces tworzenia powiązań między stronami. Wewnętrzne linki prowadzą do innych stron
          na tej samej witrynie. Dobrze zaplanowane linkowanie może znacznie poprawić widoczność strony w wyszukiwarkach
          oraz ułatwić użytkownikom poruszanie się po witrynie.
        </p>
        <p>
          Poniżej linki wewnętrzne na stronie <A to="https://wenet.pl/blog/" text={"blog.wenet.pl"} />:
        </p>
        <figure>
          <img src={data.image3.childImageSharp.fluid.src} alt={"Linki wewnętrzne WeNet.pl"} />
        </figure>
        <h3>Pozycjonowanie lokalne</h3>
        <p>
          Pozycjonowanie lokalne to proces optymalizacji witryny internetowej i jej zawartości w taki sposób, aby była
          ona łatwo odnajdywalna przez osoby poszukujące usług czy produktów w konkretnej lokalizacji.
        </p>
        <p>
          Obejmuje działania zarówno na stronie samej witryny (on-page), jak i poza nią (off-page), które mają na celu
          zwiększenie widoczności firmy w wynikach Google oraz innych wyszukiwarek.
        </p>
        <p>
          Inwestując w pozycjonowanie lokalne, zyskujesz przede wszystkim lepszą widoczność Twojej firmy online. Dzięki
          temu klienci z Twojej okolicy będą mogli łatwo Cię odnaleźć, co przekłada się na większe zainteresowanie
          Twoimi usługami czy produktami.
        </p>
        <p>
          Ponadto pozycjonowanie lokalne pozwala na skierowanie działań marketingowych do konkretnej grupy odbiorców, co
          sprawia, że są one bardziej efektywne i dają lepsze rezultaty.
        </p>
        <h3>Jak pozycjonować lokalnie?</h3>
        <p>
          Pozycjonowanie lokalne opiera się na kilku kluczowych elementach, które warto uwzględnić w swojej strategii.
        </p>
        <p>
          Przede wszystkim, należy zadbać o optymalizację witryny pod kątem fraz kluczowych związanych z lokalizacją.
          Ważne jest również, aby umieścić na stronie dane kontaktowe firmy, takie jak adres, numer telefonu czy e-mail.
        </p>
        <p>
          Kolejnym istotnym elementem jest stworzenie i optymalizacja Profilu Firmy w Google, który pozwala na
          wyświetlanie informacji o Twojej firmie wynikach wyszukiwarki oraz na Mapach Google. Warto również zadbać o
          pozyskiwanie opinii od klientów oraz budowanie wartościowych linków prowadzących do Twojej witryny.
        </p>
        <p>Poniżej Ciastkarnia Luna na Mapach Google:</p>
        <figure>
          <img src={data.image4.childImageSharp.fluid.src} alt={"Ciastkarnia Luna na Mapach Google"} />
        </figure>
        <h2>Link Building</h2>
        <p>Link Building to proces zdobywania linków zewnętrznych prowadzących do Twojej strony WWW.</p>
        <p>
          Wartościowe linki z innych witryn są jednym z głównych czynników, które wpływają na pozycję witryny. Wysokiej
          jakości linki z autorytatywnych źródeł zwiększają wiarygodność Twojej strony i sprawiają, że jest ona
          postrzegana jako wartościowa dla użytkowników. Dlatego też, aby osiągnąć sukces w pozycjonowaniu, warto
          zainwestować czas i środki w efektywny Link Building.
        </p>
        <h3>Metody zdobywania linków</h3>
        <p>
          Istnieje wiele metod zdobywania wartościowych linków do Twojej strony. Jedną z nich jest tworzenie
          atrakcyjnych treści, które będą chętnie udostępniane przez inne osoby.
        </p>
        <p>
          Możesz przygotować interesujące artykuły, infografiki czy materiały video, które będą wartościowe dla Twojej
          grupy docelowej. Inną metodą jest nawiązywanie współpracy z innymi przedsiębiorcami, blogerami czy
          influencerami, którzy mogą polecić Twoją stronę swoim odbiorcom.
        </p>
        <p>
          Warto również zgłaszać swoją stronę do katalogów branżowych, które są źródłem wartościowych linków. Pamiętaj
          jednak, aby wybierać tylko te katalogi, które są moderowane i mają dobrą reputację. Unikaj katalogów o niskiej
          jakości, które mogą tylko zaszkodzić.
        </p>
        <p>
          Również aktywność na forach internetowych i komentowanie na blogach może przyczynić się do zdobywania
          wartościowych linków. Ważne jest jednak, aby nie spamować i nie zostawiać linków bezpośrednio w komentarzach.
          Zamiast tego postaraj się nawiązać dialog z innymi użytkownikami i w miarę możliwości udzielać im
          wartościowych informacji, które będą przydatne dla nich.
        </p>
        <h3>Rodzaje linków</h3>
        <p>Warto pamiętać, że nie wszystkie linki mają taką samą wartość dla Google.</p>
        <p>
          Wyróżniamy linki dofollow oraz nofollow. Linki dofollow są najbardziej wartościowe, ponieważ przekazują moc
          pozycjonującą ze strony źródłowej na stronę docelową. Linki nofollow natomiast nie wpływają bezpośrednio na
          pozycję strony, ale mogą przyciągać ruch na Twoją stronę i zwiększać jej popularność.
        </p>
        <p>
          Kluczowe jest więc zdobywanie linków dofollow z autorytatywnych źródeł. Warto jednak pamiętać, że naturalny
          profil linków powinien zawierać również linki nofollow, ponieważ Google może podejrzewać manipulacje, jeśli
          większość Twoich linków będzie dofollow.
        </p>
        <h3>Analiza wyników i monitorowanie pozycji</h3>
        <p>Nie mniej ważnym elementem działań SEO jest analiza wyników oraz monitorowanie rankingu.</p>
        <p>
          Dzięki narzędziom takim jak Google Analytics czy Search Console można śledzić efekty działań oraz wprowadzać
          ewentualne korekty. Regularne monitorowanie pozwoli na szybkie reagowanie na zmiany algorytmów wyszukiwarek
          czy działania konkurencji.
        </p>
        <h2>Audyt SEO - co to jest i dlaczego warto go przeprowadzić?</h2>
        <p>
          Audyt SEO to kompleksowa analiza strony internetowej pod kątem jej optymalizacji dla wyszukiwarek, takich jak
          Google czy Bing.
        </p>
        <p>
          Celem tego procesu jest zidentyfikowanie wszelkich błędów, słabych punktów oraz możliwości poprawy, które
          wpłyną na lepsze pozycjonowanie witryny.
        </p>
        <p>
          Przeprowadzenie audytu pozwala nie tylko na zwiększenie widoczności strony, ale także na poprawę jej
          użyteczności oraz dostosowanie do oczekiwań użytkowników.
        </p>
        <h3>Etap pierwszy - analiza techniczna</h3>
        <p>Pierwszym krokiem w przeprowadzeniu audytu jest analiza techniczna strony.</p>
        <p>
          Jej celem jest sprawdzenie, czy witryna spełnia podstawowe wymagania wyszukiwarek, takie jak szybkość
          ładowania, dostosowanie do urządzeń mobilnych czy poprawne użycie metadanych. Warto zwrócić uwagę na takie
          elementy jak struktura URL, mapy strony, plik robots.txt czy odpowiednie wykorzystanie nagłówków H. Ważnym
          aspektem jest także sprawdzenie, czy strona nie zawiera duplikatów treści oraz czy wszystkie linki są aktywne
          i prowadzą do istniejących podstron.
        </p>
        <h3>Etap drugi - analiza słów kluczowych</h3>
        <p>
          Kolejnym etapem audytu SEO jest analiza słów kluczowych, które są istotne dla branży przedsiębiorcy oraz dla
          użytkowników poszukujących informacji na jego stronie.
        </p>
        <p>
          Proces ten polega na identyfikacji najbardziej wartościowych fraz, które mogą przyciągnąć ruch na witrynę oraz
          na ocenie ich konkurencyjności. Ważne jest także sprawdzenie, czy strona zawiera wystarczającą ilość
          unikalnych treści zawierających te frazy kluczowe oraz czy są one odpowiednio wykorzystane w tytułach, opisach
          czy nagłówkach.
        </p>
        <h3>Etap trzeci - analiza treści</h3>
        <p>
          Treść na stronie WWW to fundament skutecznego SEO. W ramach audytu warto ocenić jakość tekstów pod kątem
          merytorycznym oraz stylistycznym. Należy zwrócić uwagę na czytelność, unikatowość oraz wartość informacyjną
          publikowanych artykułów czy wpisów. Ważne jest także sprawdzenie, czy treści są dostosowane do potrzeb i
          oczekiwań grupy docelowej oraz czy zawierają odpowiednie frazy kluczowe.
        </p>
        <h3>Etap czwarty - analiza linków</h3>
        <p>
          Jak już wiesz, linki zewnętrzne i wewnętrzne mają ogromny wpływ na pozycjonowanie strony internetowej. W
          ramach audytu SEO warto sprawdzić jakość linków prowadzących do witryny oraz ich liczbę.
        </p>
        <p>
          Należy zwrócić uwagę na to, czy pochodzą one z zaufanych źródeł oraz czy są zgodne z tematyką strony. Ważne
          jest także sprawdzenie, czy strona posiada odpowiednią ilość linków wewnętrznych oraz czy prowadzą one do
          istotnych podstron.
        </p>
        <h3>Etap piąty - analiza konkurencji</h3>
        <p>
          Ostatnim etapem audytu SEO jest analiza konkurencji. Pozwala ona na ocenę działań podejmowanych przez inne
          firmy z branży oraz na identyfikację strategii, które mogą przynieść sukces w pozycjonowaniu strony
          internetowej. Warto przyjrzeć się działaniom marketingowym konkurentów, ich treściom oraz wykorzystaniu fraz
          kluczowych.
        </p>
        <p>
          Chcesz przeprowadzić audy SEO samodzielnie? Wejdź na{" "}
          <A to={"https://audytseo.wenet.pl/"} text={"https://audytseo.wenet.pl/"} />.
        </p>
        <h2>White Hat SEO vs Black Hat SEO – czyli dobre i złe praktyki SEO</h2>
        <h3>White Hat SEO – etyczne metody pozycjonowania</h3>
        <p>
          To zbiór praktyk, które są zgodne z wytycznymi Google i innych wyszukiwarek. Stosowanie tych metod ma na celu
          poprawę jakości treści na stronie oraz jej funkcjonalności. White Hat opiera się na kilku kluczowych
          aspektach, takich jak optymalizacja treści, budowanie wartościowych linków czy poprawa użyteczności strony.
        </p>
        <p>
          Obydwa rodzaje SEO mają wspólny cel – zdobycie jak najwyższej pozycji w wynikach wyszukiwania. Jednak White
          Hat SEO skupia się na długotrwałych rezultatach, które przynoszą korzyści zarówno użytkownikom, jak i
          właścicielom stron. Dzięki temu witryna staje się bardziej atrakcyjna dla odwiedzających, co zwiększa szansę
          na pozyskanie nowych klientów.
        </p>
        <p>
          Optymalizacja treści w White Hat SEO polega na tworzeniu wartościowych, unikalnych i angażujących tekstów,
          które są przydatne dla odbiorców. Dobrej jakości treść to taka, która odpowiada na pytania użytkowników,
          dostarcza im ważnych informacji i jest łatwa do zrozumienia. Warto również zadbać o odpowiednie formatowanie
          tekstu, użycie nagłówków oraz umieszczenie właściwych słów kluczowych w strategicznych miejscach.
        </p>
        <p>
          Budowanie wartościowych linków w White Hat SEO to proces zdobywania{" "}
          <strong>naturalnych odnośników do swojej strony</strong>. Można to osiągnąć poprzez publikowanie wartościowych
          treści na innych stronach, udział w branżowych forach czy współpracę z innymi przedsiębiorcami. Ważne jest,
          aby linki były uzyskiwane w sposób naturalny i nie naruszały wytycznych Google.
        </p>
        <p>
          Poprawa użyteczności strony w white hat SEO obejmuje takie działania jak{" "}
          <strong>
            optymalizacja czasu ładowania strony, dostosowanie jej do urządzeń mobilnych czy ułatwienie nawigacji
          </strong>
          . Dzięki temu, użytkownicy mogą szybko i łatwo znaleźć informacje, których szukają.
        </p>
        <h3>Black Hat SEO - nieetyczne metody pozycjonowania</h3>
        <p>
          W przeciwieństwie do White Hat SEO, Black Hat SEO to zestaw technik, które mają na celu osiągnięcie szybkich
          rezultatów, często kosztem jakości strony i doświadczenia użytkowników. Stosowanie tych metod może prowadzić
          do negatywnych konsekwencji, takich jak obniżenie pozycji w wynikach wyszukiwania czy nawet usunięcie strony z
          indeksu Google.
        </p>
        <p>
          Przykłady black hat SEO to między innymi nadmierne stosowanie fraz kluczowych, ukrywanie tekstu czy tworzenie
          niskiej jakości linków. Takie działania mają na celu manipulowanie algorytmami wyszukiwarek w celu uzyskania
          wyższej pozycji w wynikach wyszukiwania.
        </p>
        <p>
          Nadmierne stosowanie fraz kluczowych polega na ich częstym powtarzaniu w tekście, co sprawia, że treść staje
          się mało czytelna i nieprzyjazna dla użytkowników.
        </p>
        <p>
          Ukrywanie tekstu to technika polegająca na umieszczaniu niewidocznych dla oka fragmentów na stronie, które są
          widoczne tylko dla wyszukiwarek.
        </p>
        <p>
          Tworzenie niskiej jakości linków to praktyka polegająca na umieszczaniu odnośników do swojej strony na
          niewartościowych stronach internetowych lub zakupie linków od innych podmiotów.
        </p>
        <h2>Podstawy optymalizacji SEO – podsumowanie</h2>
        <p>
          SEO to niezbędny element strategii marketingowej każdego przedsiębiorcy, który chce odnieść sukces w
          Internecie. Poprzez dobór odpowiednich słów kluczowych, optymalizację techniczną strony, tworzenie
          wartościowych treści, budowanie profilu linków oraz ciągłe analizowanie wyników, można osiągnąć wysokie
          pozycje w wynikach wyszukiwania i zwiększyć widoczność firmy w sieci.
        </p>
        <p>
          <br className="ProseMirror-trailingBreak" />
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    image1: file(relativePath: { eq: "img/full/na-czym-polega-seo-image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
    image2: file(relativePath: { eq: "img/full/na-czym-polega-seo-image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
    image3: file(relativePath: { eq: "img/full/na-czym-polega-seo-image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
    image4: file(relativePath: { eq: "img/full/na-czym-polega-seo-image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
